.container {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  margin: 0px;

  background-color: #eaeaea;

  overflow-x: hidden;
}

.editor {
  flex: 1;
  position: relative;
  white-space: nowrap;
  background-color: white;

  padding: 0px 40px 0px 50px;
  margin: 10px auto;
  overflow-x: hidden;
  width: 100%;
  max-width: 1280px;
  -webkit-box-shadow: 0px 0px 27px -9px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: 0px 0px 27px -9px rgba(0, 0, 0, 0.35);
  box-shadow: 0px 0px 27px -9px rgba(0, 0, 0, 0.35);
  border-radius: 5px;
}

.container > .footer {
  background-color: black;
  color: white;
}

a {
  color: #00c46a;
}

.canvas {
  position: absolute;
  width: 100%;
  max-width: 1320px;
  height: 100%;
  overflow-y: hidden;
  overflow-x: visible;

  bottom: 10px;
}

.segments {
  position: absolute;

  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-end;
  bottom: 0px;
  padding-bottom: 40px;

  margin-right: 100px;
}

.fader {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.9);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 50000px;
  height: 100%;
  z-index: 1;
}

.cta {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 10px auto;
  padding: 20px;
  background-color: white;

  -webkit-box-shadow: 0px 0px 27px -9px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: 0px 0px 27px -9px rgba(0, 0, 0, 0.35);
  box-shadow: 0px 0px 27px -9px rgba(0, 0, 0, 0.35);
  border-radius: 5px;
}

.cta > .form-input {
  max-width: 100px;
}

.btn {
  display: inline-block;
  background-color: white;
  border: 1px solid lightgray;
  text-align: center;
  font-size: 13px;
  border-radius: 5px;
  min-width: 50px;
}

.btn > svg {
  display: block;
  margin: 0 auto;
}

.btn-icon {
  width: 30px;
  margin: 0 auto;
}

.btn-primary {
  background-color: #00c46a;
  color: white;
  padding: 5px 20px;
}

.btn-primary:hover {
  background-color: #27ae60;
}

.btn-secondary {
  background-color: white;
  color: black;
  padding: 5px 20px;
}

.btn-secondary:hover {
  background-color: #eeeeee;
}

.btn-square {
  color: white;
  width: 50px;
  height: 50px;
}

.form-input {
  display: flex;
  flex-direction: column;
}

.textInput {
  max-width: 90px;
  font-size: 20px;
  border: 1px solid lightgray;
  text-align: center;
}

.selectInput {
  font-size: 16px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 10px;
  border-radius: 5px;
}

label {
  font-size: 10px;
  text-align: center;
  padding: 5px;
}

.actions {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 220px;
  margin: 0 auto;
  padding: 5px;
  z-index: 10;
}

.actions > button {
  padding: 20px;
  margin: 0 5px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  border: 0;
  border-radius: 50%;
}

.slider {
  position: absolute;
  top: 10px;
  left: 0px;
}

.workoutLength {
  display: inline-block;
}

.alert {
  color: "red";
}

.message {
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 400px;
  text-align: center;
  padding: 20px;
  border-radius: 10px;
  z-index: 1000;
}

.message > .close {
  color: white;
}

.loading {
  color: white;
  background-color: rgba(0, 0, 0, 0.7);
}

.error {
  color: white;
  background-color: rgba(196, 13, 13, 0.7);
}

.close {
  background-color: rgba(0, 0, 0, 0);
  border: 0;
  float: right;
  color: white;
}

.info {
  margin: 0 auto;
  text-align: left;
  max-width: 1360px;
  width: 100%;

  display: flex;
  flex-direction: row;
}

.info > .title {
  flex: 1;
}

.info > .title > h1 {
  padding: 10px 0;
  margin: 0;
}

.info > .title > p {
  font-style: italic;
  margin: 10px 0 0 0;
}

.info > .workout {
  padding: 10px 0 10px 10px;
  display: flex;
  flex-direction: row;
}
.info > .workout > .form-input {
   padding: 0 6px;
}

.run-workout {
  display: flex;
  margin: 0 auto;
  flex-direction: row;
  width: 100%;
  max-width: 1360px;
}

.run-workout > .form-input {
  padding-right: 10px;
  align-self: flex-end;
}

.timePicker {
  width: 120px;
}

.timePicker > input {
  font-size: 18px;
  font-family: monospace;
  color: black;
}

.rc-time-picker-panel-input {
  font-size: 18px;
  font-family: monospace;
}

.rc-time-picker-panel-select > ul > li {
  font-size: 16px;
  font-family: monospace;
}

.text-editor {
  display: flex;
  max-width: 1360px;
  align-self: center;
  width: 100%;
  height: 200px;
}

.submit {
  background-color: white;
  height: 40px;
  width: 100%;
  border: 1px solid black;
  align-self: center;
}


.gpt {
  align-self: center;
  width: 60%;
  grid-template-columns: 1fr;
  margin: 10px auto;
}

.text-editor-instructions {
  width: 30%;
  padding: 5px 20px;
  margin: 0 10px;
  overflow-y: scroll;
  background-color: whitesmoke;
}

.text-editor-instructions > p {
  margin: 5px 0;
  line-height: 1.3;
  font-size: 14px;
  color: #333;
}

.text-editor-instructions > p > span {
  background-color: palevioletred;
  color: white;
  padding: 0 5px;
  font-size: 14px;
}

.text-editor-instructions > p > code {
  display: block;
  background-color: lightgrey;
  font-size: 14px;
  margin: 5px 0;
}

.text-editor-instructions > h2 {
  font-size: 14px;
  margin: 0;
  padding: 0;
  line-height: 2;
}

.text-editor-instructions > h3 {
  font-size: 14px;
  margin: 10px 0;
  line-height: 1;
}

.text-editor-textarea {
  width: 70%;

  font-size: 14px;
  box-sizing: border-box;
  padding: 20px;

  -webkit-box-shadow: 0px 0px 27px -9px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: 0px 0px 27px -9px rgba(0, 0, 0, 0.35);
  box-shadow: 0px 0px 27px -9px rgba(0, 0, 0, 0.35);
  border-radius: 5px;
}

.text-editor-textarea2 {
  width: 100%;

  font-size: 14px;
  box-sizing: border-box;
  padding: 20px;

  -webkit-box-shadow: 0px 0px 27px -9px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: 0px 0px 27px -9px rgba(0, 0, 0, 0.35);
  box-shadow: 0px 0px 27px -9px rgba(0, 0, 0, 0.35);
  border-radius: 5px;
}
