.popup-background {
  position: fixed;
  background-color: rgba(0,0,0,0.5);
  top:0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

.popup {
  position: fixed;
  position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  
  padding: 10px;

  align-self: center;
  margin: 0 auto;  
  background-color: white;
  border-radius: 5px;

  box-shadow: -5px 1px 51px 0px rgba(0,0,0,0.75);
  -webkit-box-shadow: -5px 1px 51px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: -5px 1px 51px 0px rgba(0,0,0,0.75);

  overflow-y: scroll;
}

.form-control {
  margin: 10px 0;
  width: 100%;
}

.form-control > label {
  font-size: 14px;
  color: gray;
  display: block;
  text-align: left;
}

.form-control > input, .form-control > textarea {
  display: block;
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  
  font-size: 16px; 
  border: 1px solid #AAA;
  border-radius: 5px;
  box-sizing:border-box;
}

 .form-control > button {
  
  padding: 10px;
  margin-top: 10px;  

  width: 50%;
  
  font-size: 16px; 
  border: 1px solid #EEEEEE;
  border-radius: 5px;
  box-sizing:border-box;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  color: black;  
}