.edit {
  display: inline-block;  
  padding: 5px;
  background-color: white;
}
.line {  
  position: absolute;
  top: 30px;
  left: 0px;
  right: 0;
  height: 90vh;
  width: 1px;
  border-left: 1px dotted gray;  
  z-index: 0;
}
.react-draggable {
  position: absolute;
}