.workouts {
  margin: 0;
  max-height: 500px;
}

.workouts > .title {
  padding: 10px 0;
}

.workouts > a {  
  display: block;
  padding: 20px;
}

.workouts > a > .title{  
  color: black;
  font-weight: bold;
}

.workouts > a > .description{  
  color: gray;
  font-weight: bold;
}